import React from 'react';
import { FaSearch, FaShoppingCart, FaHome } from 'react-icons/fa';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate, Link } from 'react-router-dom';
import logo from '../components/Logo.png'; // Adjust the path as necessary

const Header = ({ totalItems, searchTerm, setSearchTerm, currentPath }) => {
  const navigate = useNavigate();

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const hideCartIcon = currentPath === '/checkout' || currentPath === '/cart';

  return (
    <div>
      <header className="header" style={styles.header}>
        <div className="container d-flex align-items-center" style={styles.container}>
          <div className="logo d-flex align-items-center mx-auto" style={styles.logo}>
            <img src={logo} alt="Happy Store" style={styles.logoImg} />
          </div>
          <div className="search-bar d-flex align-items-center flex-grow-1 mx-3" style={styles.searchBar}>
            <FaHome style={styles.homeIcon} onClick={() => navigate('/')} />
            <input
              type="text"
              className="form-control"
              placeholder="Search"
              value={searchTerm}
              onChange={handleSearchChange}
              style={styles.searchInput}
            />
            <button className="btn btn-info" style={styles.searchBtn}>
              <FaSearch />
            </button>
          </div>
          <div className="icons d-flex align-items-center" style={styles.icons}>
            {!hideCartIcon && (
              <div className="icon cart-icon" style={styles.icon} onClick={() => navigate('/cart')}>
                <FaShoppingCart />
                <span className="icon-count" style={styles.iconCount}>{totalItems}</span>
              </div>
            )}
          </div>
        </div>
      </header>
      <nav className="container mt-3">
        <ul className="nav nav-tabs">
          <li className="nav-item">
            <Link className="nav-link" to="/">Book Store</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/stationery">Stationery Store</Link>
          </li>
        </ul>
      </nav>
      <style>
        {`
          @media (max-width: 991px) {
            .header .container {
              flex-direction: column;
            }
            .header .logo {
              margin-bottom: 10px;
            }
            .header .icons {
              position: fixed;
              bottom: 10px;
              right: 10px;
              background: yellow;
              border-radius: 50%;
              padding: 10px;
              z-index: 1000; /* Ensure the cart icon is above other elements */
            }
          }
          .icon-count {
            top: -10px !important; /* Adjust to ensure the count is visible */
            right: -10px !important; /* Adjust to ensure the count is visible */
          }
        `}
      </style>
    </div>
  );
};

const styles = {
  header: {
    padding: '10px 0',
    backgroundColor: '#FFDE59',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  logo: {
    display: 'flex',
    alignItems: 'center',
  },
  logoImg: {
    width: 'auto',
    height: '60px',
  },
  logoText: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginLeft: '10px',
  },
  logoSubtext: {
    fontSize: '14px',
    color: '#555',
    marginLeft: '5px',
  },
  searchBar: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'white',
    borderRadius: '20px',
    padding: '5px',
    width: '100%',
  },
  homeIcon: {
    fontSize: '24px',
    color: '#000',
    marginRight: '10px',
    cursor: 'pointer',
  },
  searchInput: {
    border: 'none',
    outline: 'none',
    width: '100%',
    padding: '5px 10px',
  },
  searchBtn: {
    border: 'none',
    borderRadius: '50%',
    padding: '5px 10px',
  },
  icons: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    position: 'relative',
    fontSize: '24px',
    marginLeft: '20px',
    cursor: 'pointer',
  },
  iconCount: {
    position: 'absolute',
    top: '-10px',
    right: '-10px',
    backgroundColor: 'red',
    color: 'white',
    borderRadius: '50%',
    padding: '2px 6px',
    fontSize: '12px',
  },
};

export default Header;


// import React, { useState, useRef, useEffect } from "react";
// import { FaSearch, FaShoppingCart, FaHome } from "react-icons/fa";
// import "bootstrap/dist/css/bootstrap.min.css";
// import { useNavigate, Link } from "react-router-dom";
// import logo from "../components/Logo.png"; // Adjust path as needed

// const Header = ({ totalItems, searchTerm, setSearchTerm, currentPath }) => {
//   const navigate = useNavigate();
//   const [showSuggestions, setShowSuggestions] = useState(false);
//   const searchRef = useRef(null);

//   // Sample book/product names (Replace this with API data if needed)
//   const items = [
//     "The Great Gatsby",
//     "1984",
//     "To Kill a Mockingbird",
//     "Harry Potter",
//     "The Catcher in the Rye",
//     "Pride and Prejudice",
//     "Moby-Dick",
//     "Lord of the Rings",
//   ];

//   // Filter items based on search input
//   const filteredItems = searchTerm
//     ? items.filter((item) =>
//         item.toLowerCase().includes(searchTerm.toLowerCase())
//       )
//     : [];

//   // Handle search input change
//   const handleSearchChange = (e) => {
//     setSearchTerm(e.target.value);
//     setShowSuggestions(true);
//   };

//   // Handle clicking on a suggested item
//   const handleSelectItem = (item) => {
//     setSearchTerm(item); // Autofill search bar
//     setShowSuggestions(false);
//   };

//   // Close suggestions when clicking outside
//   useEffect(() => {
//     const handleClickOutside = (event) => {
//       if (searchRef.current && !searchRef.current.contains(event.target)) {
//         setShowSuggestions(false);
//       }
//     };
//     document.addEventListener("mousedown", handleClickOutside);
//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, []);

//   return (
//     <div>
//       <header className="header" style={styles.header}>
//         <div className="container d-flex align-items-center" style={styles.container}>
//           <div className="logo d-flex align-items-center mx-auto" style={styles.logo}>
//             <img src={logo} alt="Happy Store" style={styles.logoImg} />
//           </div>
//           <div className="search-container" ref={searchRef} style={{ position: "relative", flexGrow: 1 }}>
//             <div className="search-bar d-flex align-items-center mx-3" style={styles.searchBar}>
//               <FaHome style={styles.homeIcon} onClick={() => navigate("/")} />
//               <input
//                 type="text"
//                 className="form-control"
//                 placeholder="Search"
//                 value={searchTerm}
//                 onChange={handleSearchChange}
//                 style={styles.searchInput}
//               />
//               <button className="btn btn-info" style={styles.searchBtn}>
//                 <FaSearch />
//               </button>
//             </div>

//             {/* Suggestion Dropdown */}
//             {showSuggestions && filteredItems.length > 0 && (
//               <ul className="suggestion-dropdown" style={styles.dropdown}>
//                 {filteredItems.map((item, index) => (
//                   <li key={index} style={styles.dropdownItem} onClick={() => handleSelectItem(item)}>
//                     {item}
//                   </li>
//                 ))}
//               </ul>
//             )}
//           </div>

//           <div className="icons d-flex align-items-center" style={styles.icons}>
//             {!["/checkout", "/cart"].includes(currentPath) && (
//               <div className="icon cart-icon" style={styles.icon} onClick={() => navigate("/cart")}>
//                 <FaShoppingCart />
//                 <span className="icon-count" style={styles.iconCount}>{totalItems}</span>
//               </div>
//             )}
//           </div>
//         </div>
//       </header>

//       {/* Navigation Tabs */}
//       <nav className="container mt-3">
//         <ul className="nav nav-tabs">
//           <li className="nav-item">
//             <Link className="nav-link" to="/">Book Store</Link>
//           </li>
//           <li className="nav-item">
//             <Link className="nav-link" to="/stationery">Stationery Store</Link>
//           </li>
//         </ul>
//       </nav>
//     </div>
//   );
// };

// const styles = {
//   header: {
//     padding: "10px 0",
//     backgroundColor: "#FFDE59",
//   },
//   container: {
//     display: "flex",
//     alignItems: "center",
//   },
//   logo: {
//     display: "flex",
//     alignItems: "center",
//   },
//   logoImg: {
//     width: "auto",
//     height: "60px",
//   },
//   searchBar: {
//     display: "flex",
//     alignItems: "center",
//     backgroundColor: "white",
//     borderRadius: "20px",
//     padding: "5px",
//     width: "100%",
//     position: "relative",
//   },
//   homeIcon: {
//     fontSize: "24px",
//     color: "#000",
//     marginRight: "10px",
//     cursor: "pointer",
//   },
//   searchInput: {
//     border: "none",
//     outline: "none",
//     width: "100%",
//     padding: "5px 10px",
//   },
//   searchBtn: {
//     border: "none",
//     borderRadius: "50%",
//     padding: "5px 10px",
//   },
//   icons: {
//     display: "flex",
//     alignItems: "center",
//   },
//   icon: {
//     position: "relative",
//     fontSize: "24px",
//     marginLeft: "20px",
//     cursor: "pointer",
//   },
//   iconCount: {
//     position: "absolute",
//     top: "-10px",
//     right: "-10px",
//     backgroundColor: "red",
//     color: "white",
//     borderRadius: "50%",
//     padding: "2px 6px",
//     fontSize: "12px",
//   },
//   dropdown: {
//     position: "absolute",
//     top: "100%",
//     left: "0",
//     width: "100%",
//     backgroundColor: "#fff",
//     border: "1px solid #ddd",
//     borderRadius: "5px",
//     boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
//     zIndex: 1000,
//     listStyleType: "none",
//     padding: "5px",
//     marginTop: "5px",
//   },
//   dropdownItem: {
//     padding: "8px",
//     cursor: "pointer",
//     borderBottom: "1px solid #eee",
//   },
// };

// export default Header;
